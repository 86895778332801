import React, { useEffect, useState } from "react";
import { imgDb } from "./firebaseConfig";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import ImageGallery from "react-image-gallery";


export const Gallery = (props) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imagesRef = ref(imgDb, 'img/');
        const response = await listAll(imagesRef);
        const urls = await Promise.all(
          response.items.map(item => getDownloadURL(item))
        );
        const imagesList = urls.map((name) =>({
          original:name,
        }));
        setImages(imagesList);
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div id="gallery" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Galería</h2>
          <p>
            Explora los momentos más significativos de nuestro reciente evento de constelación familiar.
          </p>
        </div>
        <div className="row">
          <ImageGallery items={images} />
        </div>
      </div>
    </div>
  );
};
