// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDtRJ6SxYCe5Ioys7qHoNb9brIOC5fcmpI",
    authDomain: "shirleyramirez-8664f.firebaseapp.com",
    projectId: "shirleyramirez-8664f",
    storageBucket: "shirleyramirez-8664f.appspot.com",
    messagingSenderId: "307875850223",
    appId: "1:307875850223:web:2ff6cdb9739c598d01f81a",
    measurementId: "G-WRDG8CKY0P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const imgDb = getStorage(app)


export { imgDb };

